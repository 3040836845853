import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image-es5';

function Splash() {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "splash-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900 ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      className="flex flex-col justify-between items-center bg-top"
      fluid={file.childImageSharp.fluid}
      backgroundColor={'black'}
      style={{ minHeight: '82vh' }}
    >

      <div
        className="text-center my-8 px-4"
        style={{ fontFamily: 'Libre Baskerville, serif' }}
      >
        <h1 className="mt-6 md:mt-16 lg:mt-8 text-white text-2xl md:text-4xl lg:text-6xl font-medium tracking-wider">
          Wellspring Craniosacral Therapy
        </h1>

        <h2 className="mt-4 md:mt-8 lg:mt-6 text-white text-md md:text-2xl lg:text-2xl font-thin md:font-bold tracking-wider">
          A Biodynamic Approach to Health
        </h2>

        <h2 className="mt-4 text-white text-md md:text-2xl lg:text-2xl font-thin md:font-bold tracking-wider">
          &mdash; Est. 2017 &mdash;
        </h2>
      </div>

      <div className="flex flex-col items-center mb-16">
      {/* <Link */}
      {/*   to="/appointments" */}
      {/*   className="bg-transparent hover:bg-gray-100 hover:text-black focus:outline-none text-gray-100 font-bold tracking-wider mt-2 mb-4 py-2 px-6 md:py-4 md:px-8 border border-gray-100 rounded shadow" */}
      {/* > */}
      {/*   Book Now */}
      {/* </Link> */}

      <Link
        to="/about-bcst"
        className="bg-transparent hover:bg-gray-100 hover:text-black focus:outline-none text-gray-100 font-bold tracking-wider mt-2 mb-4 py-2 px-6 md:py-4 md:px-8 border border-gray-100 rounded shadow"
      >
        What is BCST?
      </Link>
      </div>
    </BackgroundImage>
  );
}

export default Splash;
