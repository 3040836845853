import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Splash from '../components/Splash';

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Wellspring BCST`, `Wellspring Cranio`, `Wellspring Biodynamic Craniosacral Therapy`]}
        title="Home"
      />

      <Splash />
    </Layout>
  );
}

export default IndexPage;
